import { Injectable } from '@angular/core';
import {
  HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SortDirection } from '@angular/material/sort';
import { catchError, filter, map } from 'rxjs/operators';
import { CollappFilterDropdownListRadioSetting } from '../../models/collapp-filter-dropdown-list-radio.interface';
import { AvailableRequestRequestOptions } from '../../models/available-projects-request.options';
import { AvailalbeProjectListResponse } from '../interfaces/responses/available-project-list.response';
import { BasicWorkpackagesRequestOptions } from '../../models/basic-workpackages-request.options';
import { BasicWorkPackageDetailResponse } from '../interfaces/responses/basic-work-package-detail.response';
import { LevelInfoResponse } from '../interfaces/responses/level-info.response';
import { getEndpointUrl, obj2fd } from '../utilities/api.utility';
import { CountryListResponse } from '../interfaces/responses/country-list.response';
import { InlineFileDto } from '../interfaces/dtos/inline-file.dto';
import { GateListResponse } from '../interfaces/responses/gate-list.response';
import { ImportUserAndUnitResponse } from '../interfaces/responses/import-user-and-unit.response';
import { LevelPlanningUpdateRequest } from '../interfaces/requests/level-planning-update.request';
import { ProjectStructurePresetUpdateRequest } from '../interfaces/requests/project-structure-preset-update.request';
import { UserSettingDto } from '../interfaces/dtos/user-setting.dto';
import { ProjectListResponse } from '../interfaces/responses/project-list.response';
import { ProjectViewResponse } from '../interfaces/responses/project-view.response';
import { ProjectActivityViewResponse } from '../interfaces/responses/project-activity-view.response';
import { ProjectImportListResponse } from '../interfaces/responses/project-import-list.response';
import { UnitListResponse } from '../interfaces/responses/unit-list.response';
import { UnitCreateRequest } from '../interfaces/requests/unit-create.request';
import { UnitSelectorListResponse } from '../interfaces/responses/unit-selector-list.response';
import { UnitDto } from '../interfaces/dtos/unit.dto';
import { UnitSlimDto } from '../interfaces/dtos/unit-slim.dto';
import { UnitUpdateRequest } from '../interfaces/requests/unit-update.request';
import { UserCreateRequest } from '../interfaces/requests/user-create.request';
import { UserDto } from '../interfaces/dtos/user.dto';
import { UserUpdateRequest } from '../interfaces/requests/user-update.request';
import { WorkPackageListResponse } from '../interfaces/responses/work-package-list.response';
import { WorkPackageViewResponse } from '../interfaces/responses/work-package-view.response';
import { WorkPackageUpdateStatusRequest } from '../interfaces/requests/work-package-update-status.request';
import { ProjectCreateRequest } from '../interfaces/requests/project-create.request';
import { ProjectUpdateRequest } from '../interfaces/requests/project-update.request';
import { WorkPackageCreateRequest } from '../interfaces/requests/work-package-create.request';
import { WorkPackageUpdateRequest } from '../interfaces/requests/work-package-update.request';
import { RoleListResponse } from '../interfaces/responses/role-list.response';
import { ProjectScopeViewResponse } from '../interfaces/responses/project-scope-view.response';
import { ProjectCreateResponse } from '../interfaces/responses/project-create.response';
import { WorkPackageCreateResponse } from '../interfaces/responses/work-package-create.response';
import { ProjectImportListDto } from '../interfaces/dtos/project-import-list.dto';
import { LevelListResponse } from '../interfaces/responses/level-list.response';
import { ProjectTypeListResponse } from '../interfaces/responses/project-type-list.response';
import { LevelCreateRequest } from '../interfaces/requests/level-create.request';
import { LevelCreateResponse } from '../interfaces/responses/level-create.response';
import { WorkPackageSubmitterSettingsUpdateRequest } from '../interfaces/requests/work-package-submitter-settings-update.request';
import { WorkPackageSupplierSettingsUpdateRequest } from '../interfaces/requests/work-package-supplier-settings-update.request';
import { WorkPackageResourcePlanningViewResponse } from '../interfaces/responses/work-package-resource-planning-view.response';
import { WorkPackageResourcePlanningUpdateRequest } from '../interfaces/requests/work-package-resource-planning-update.request';
import {
  ListQueryOptions,
  UserListQueryOptions,
  UserResourceListQueryOptions,
  WorkPackageListOptions,
  UnitQuestionsListOptions,
} from '../list-query-options';
import { WorkPackageStatus } from '../../models/work-package-status.enum';
import { ScopeChangeCreateRequest } from '../interfaces/requests/scope-change-create.request';
import { ScopeChangeUpdateRequest } from '../interfaces/requests/scope-change-update.request';
import { ProjectUpdateFromSapRequest } from '../interfaces/requests/project-update-from-sap.request';
import { UnitCreateResponse } from '../interfaces/responses/unit-create.response';
import { TimecardViewResponse } from '../interfaces/responses/timecard-view.response';
import { UserCreateResponse } from '../interfaces/responses/user-create.response';
import { WorkPackageTransitionInformationResponse } from '../interfaces/responses/work-package-transition-information.response';
import { TimecardSaveRequest } from '../interfaces/requests/timecard-save.request';
import { TimeTrackingWeekNavigatorViewResponse } from '../interfaces/responses/time-tracking-week-navigator-view.response';
import { RatingListResponse } from '../interfaces/responses/rating-list.response';
import { WorkPackageCloseRequest } from '../interfaces/requests/work-package-close.request';
import { SuspensionCreateRequest } from '../interfaces/requests/suspension-create.request';
import { UnitsViewResponse } from '../interfaces/responses/units-view.response';
import { UnitViewResponse } from '../interfaces/responses/unit-view.response';
import { UserViewResponse } from '../interfaces/responses/user-view.response';
import { UsersViewResponse } from '../interfaces/responses/users-view.response';
import { WorkPackageUploadOutputDocumentsRequest } from '../interfaces/requests/work-package-upload-output-documents.request';
import { UnitSelectorAvailableRequestOptions, UnitSelectorRequestOptions } from './unit.service';
import { FileDownloadResponse } from '../interfaces/responses/file-download.response';
import { QuestionListResponse } from '../interfaces/responses/question-list.response';
import { WorkPackageQuestionColumnFilters } from '../filter-columns';
import { QuestionCreateResponse } from '../interfaces/responses/question-create.response';
import { QuestionUpdateRequest } from '../interfaces/requests/question-update.request';
import { QuestionCreateRequest } from '../interfaces/requests/question-create.request';
import { ReplyCreateResponse } from '../interfaces/responses/reply-create.response';
import { ReplyCreateRequest } from '../interfaces/requests/reply-create.request';
import { ConclusionCreateRequest } from '../interfaces/requests/conclusion-create.request';
import { ConclusionCreateResponse } from '../interfaces/responses/conclusion-create.response';
import { ReopenCreateRequest } from '../interfaces/requests/reopen-create.request';
import { ReopenCreateResponse } from '../interfaces/responses/reopen-create.response';
import { TimesheetViewResponse } from '../interfaces/responses/timesheet-view.response';
import { TimecardStatusUpdateRequest } from '../interfaces/requests/timecard-status-update.request';
import { WorkPackageDuplicateRequest } from '../interfaces/requests/work-package-duplicate.request';
import { ProjectSelectorListResponse } from '../interfaces/responses/project-selector-list.response';
import { SelectorRequestOptions } from '../../models/selector-request.options';
import { ActivityFetchType } from '../../models/activity-fetch-type.enum';
import { DashboardViewResponse } from '../interfaces/responses/dashboard-view.response';
import { ProjectDashboardDto } from '../interfaces/dtos/project-dashboard.dto';
import { WorkPackageDashboardDto } from '../interfaces/dtos/work-package-dashboard.dto';
import { QuestionDashboardDto } from '../interfaces/dtos/question-dashboard.dto';
import { TimeZoneDto } from '../interfaces/dtos/time-zone.dto';
import { UserSlimDto } from '../interfaces/dtos/user-slim.dto';
import { TimesheetExportTemplateResponse } from '../interfaces/responses/timesheet-export-template.response';
import { WorkPackageReworkCreateRequest } from '../interfaces/requests/work-package-rework-create.request';
import { UserSettingRequest } from '../interfaces/requests/user-setting.request';
import { WorkPackageViewListResponse } from '../interfaces/responses/work-package-view-list.response';
import { AttachmentListResponse } from '../interfaces/responses/attachment-list.response';
import { DocumentType } from '../../models/document-type.enum';
import { LevelSwapRequest } from '../interfaces/requests/level-swap.request';
import { DefectListResponse } from '../interfaces/responses/defect-list.response';
import { ReplyListResponse } from '../interfaces/responses/reply-list.response';
import { QuestionUserNotificationListResponse } from '../interfaces/responses/question-user-notification-list.response';
import { DefaultNonProductiveCategoryResponse } from '../interfaces/responses/default-non-productive-category.response';
import { CustomHttpParams } from '../custom-http-params';

import { QuestionsViewListResponse } from '../interfaces/responses/questions-view-list.response';
import { AttachmentOutdateRequest } from '../interfaces/requests/attachment-outdate-request';
import { AttachmentOutdateResponse } from '../interfaces/responses/attachment-outdate.response';
import { QuestionAction } from '../../models/question-action.enum';
import { FeedbackCreateRequest } from '../interfaces/requests/feedback-create.request';
import { UserListViewResponse } from '../interfaces/responses/user-list-view.response';
import { AvailableResourcesResponse } from '../interfaces/responses/available-resources.response';
import { ResponsibleUserDto } from '../interfaces/dtos/responsible-user.dto';
import { UserPlanningViewResponse } from '../interfaces/responses/user-planning-view.response';
import { PlanningSaveRequest } from '../interfaces/requests/planning-save-request';
import { ProjectStructureResponse } from '../interfaces/responses/project-structure.response';
import { UnitPlanningViewResponse } from '../interfaces/responses/unit-planning-view.response';
import { NonHumanResourceListResponse } from '../interfaces/responses/non-human-resource-list.response';
import { NonHumanResourceViewResponse } from '../interfaces/responses/non-human-resource-view.response';
import { NonHumanResourceCreateRequest } from '../interfaces/requests/non-human-resource-create.request';
import { NonHumanResourceCreateResponse } from '../interfaces/responses/non-human-resource-create.response';
import { NonHumanResourceUpdateRequest } from '../interfaces/requests/non-human-resource-update.request';
import { ResponsibleResourceDto } from '../interfaces/dtos/responsible-resource.dto';
import { UnitPlanningUserListViewResponse } from '../interfaces/responses/unit-planning-user-list-view.response';
import { NonHumanResourcePlanningViewResponse } from '../interfaces/responses/non-human-resource-planning-view.response';
import { NonHumanResourceDto } from '../interfaces/dtos/non-human-resource.dto';
import { UserNotificationViewResponse } from '../interfaces/responses/user-notification-view.response';
import { OldPlannedResourcesRequest } from '../interfaces/requests/old-planned-resources.request';
import { UserNotificationSettingsUpdateRequest } from '../interfaces/requests/user-notification-settings-update.request';
import { UserNotificationSettingsDto } from '../interfaces/dtos/user-notification-settings.dto';
import { ResourceLinkDto } from '../interfaces/dtos/resource-link.dto';
import { SkillsResponse } from '../interfaces/responses/skills.response';
import { SkillCategoryDto } from '../interfaces/dtos/skill-category.dto';
import { UpdateSkillCategoryRequest } from '../interfaces/requests/update-skill-category.request';
import { UpdateSkillsRequest } from '../interfaces/requests/update-skills.request';
import { UnitSkillDto } from '../interfaces/dtos/unit-skill.dto';
import { SkillRatingResponse } from '../interfaces/responses/skill-rating.response';
import { UpdateUserSkillRatingsRequest } from '../interfaces/requests/update-user-skill-ratings.request';
import { UnitPlanningViewRequest } from '../interfaces/requests/unit-planning-view.request';
import { DashboardWorkPackageViewType } from '../../models/dashboard-work-package-view-type.enum';
import { DashboardFiltersVisibilityResponse } from '../interfaces/responses/dashboard-filters-visibility.response';
import { UnitSlimDtoWithoutCoordinatorDto } from '../interfaces/dtos/unit-slim-without-coordinator.dto';
import { BackgroundColorResponse } from '../interfaces/responses/background-colors-response';
import { BackgroundColorsRequest } from '../interfaces/requests/background-colors-request';
import { UserPreferencesUpdateRequest } from '../interfaces/requests/user-preferences-update.request';
import { WorkPackageMoveRequest } from '../interfaces/requests/work-package-move.request';
import { BasicWorkPackageViewListResponse } from '../interfaces/responses/basic-work-package-view-list.response';
import { UserCalendarLinksResponse } from '../interfaces/responses/user-calendar-links-response';
import { ProjectStructureFiltersSettingsModel } from '../../models/project-structure-filter-settings.interface';
import { CustomHttpErrorResponse } from '../models/custom-http-error-response.class';
import { environment } from '../../../environments/environment';
import { AdhocWorkpackageRequestModel } from '../models/requests/adhoc-workpackage.request.model';
import { ContractDto } from '../interfaces/dtos/contract.dto';
import { AdditionalSuppliersUpdateRequest } from '../interfaces/requests/work-package-additional-suppliers-update.request';
import { ContractDtoModel } from '../models/dtos/contract.dto.model';
import { WorkpackageSupplierSettingsDto } from '../interfaces/dtos/workpackageSupplierSettings.dto';
import { ProjectPlanningViewRequestModel } from '../models/requests/project-planning-view.request.model';
import { ProjectPlanningViewResponse } from '../interfaces/responses/project-planning-view.response';
import { CostObjectDto } from '../interfaces/dtos/cost-object.dto';
import { ActivityTypeDto } from '../interfaces/dtos/unit-cost-center-activity-type.dto';
import { CostCenterDto } from '../interfaces/dtos/cost-center.dto';
import { TimesheetExportViewType } from 'src/app/modules/timesheet/timesheet.component';
import { PublicHolidayDeleteDto } from 'src/app/modules/administration/holidays/models/public-holiday-delete.dto';
import { PublicHolidaysUpdateDto, PublicHolidayUpdateDto } from 'src/app/modules/administration/holidays/models/public-holiday-update.dto';
import { PublicHolidaysCreateCopy } from 'src/app/modules/administration/holidays/models/public-holiday-create-copy.dto';
import { PublicHolidayDto } from 'src/app/modules/administration/holidays/models/public-holiday.dto';
import { PublicHolidaysCitiesDto } from 'src/app/modules/administration/holidays/models/public-holiday-cities.dto';
import { encodeUri } from '@azure/core-http';

/**
 * Workpackage Collaboration Platform API
 * Version: v1
 *
 * Built from Swagger Specification 2.0 on 2018-10-12T14:34:52.808Z
 */
@Injectable({
  providedIn: 'root',
})
export class CollAppApiService {
  static readonly version: string = 'v1';

  constructor(private httpClient: HttpClient) { }

  /**
   * Gets a list of all countries
   *
   * @return {Observable<HttpEvent<CountryListResponse>>}
   */
  getCountries$(): Observable<HttpEvent<CountryListResponse>> {
    const url = '/Lookup/Countries';

    return this.request$<CountryListResponse>('GET', url, null);
  }

  /**
   * Gets a list of names and employee numbers of all users filtered by a part of the employee number
   */
  getAllUsersEmployeeNumber$(filterString: string, limit = 20): Observable<HttpEvent<UserListViewResponse>> {
    const url = '/Lookup/AllUsersEmployeeNumber';
    const queryParameters = new CustomHttpParams({filterString, limit});

    return this.request$<UserListViewResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a list of all cost centers for a contract by unitId of the unit the contract belongs to
   */
  getContractCostCenters$(unitId: number): Observable<HttpEvent<CostCenterDto[]>> {
    const url = '/Lookup/ContractCostCenters';
    const queryParameters = new CustomHttpParams({unitId});

    return this.request$<CostCenterDto[]>('GET', url, null, queryParameters);
  }

  /**
   * Gets a list of all Cost Objects
   *
   * @return {Observable<HttpEvent<CostObjectDto[]>>}
   */
  getCostObjects$(filterString: string, userId: string, limit = 20): Observable<HttpEvent<CostObjectDto[]>> {
    const url = '/Lookup/CostObjects';
    const queryParameters = new CustomHttpParams({filterString, userId, limit});
    return this.request$<CostObjectDto[]>('GET', url, null, queryParameters);
  }

  /**
   * Gets a list of all Cost Objects filtered by the given unit
   *
   * @return {Observable<HttpEvent<CostObjectDto[]>>}
   */
  getCostObjectsForContracts$(filterString: string, unitId: number, limit = 20): Observable<HttpEvent<CostObjectDto[]>> {
    const url = '/Lookup/CostObjectsForContracts';
    const queryParameters = new CustomHttpParams({filterString, unitId, limit});
    return this.request$<CostObjectDto[]>('GET', url, null, queryParameters);
  }

  getCostObjectsByUnitId$(filterString: string, unitId: number, limit = 20): Observable<HttpEvent<CostObjectDto[]>> {
    const url = '/Lookup/CostObjectsByUnit';
    const queryParameters = new CustomHttpParams({filterString, unitId, limit});

    return this.request$<CostObjectDto[]>('GET', url, null, queryParameters);
  }

  getAllPayrollCompanies$(): Observable<HttpEvent<string[]>> {
    const url = '/Lookup/AllPayrollCompanies';

    return this.request$<string[]>('GET', url, null);
  }

  getprofitCentersByPayrollCompany$(payrollCompany: string): Observable<HttpEvent<string[]>> {
    const url = '/Lookup/ProfitCentersByPayrollCompany';
    const queryParameters = new CustomHttpParams({payrollCompany});

    return this.request$<string[]>('GET', url, null, queryParameters);
  }

  /**
   * Gets a list of all links
   *
   * @return {Observable<HttpEvent<ResourceLinkDto[]>>}
   */
  getLinks$(): Observable<HttpEvent<ResourceLinkDto[]>> {
    const url = '/Lookup/Links';

    return this.request$<ResourceLinkDto[]>('GET', url, null);
  }

  /**
   * Gets an attachments URL including the SAS token
   *
   * @param {string} attachmentId
   * @return {Observable<HttpEvent<FileDownloadResponse>>}
   */
  getDownloadUrl$(attachmentId: string): Observable<HttpEvent<FileDownloadResponse>> {
    const url = `/Files/${attachmentId}`;

    return this.request$<FileDownloadResponse>('GET', url, null);
  }

  /**
   * Marks an attachment as outdated
   *
   * @param {string} attachmentId
   * @param {AttachmentOutdateRequest} request
   * @return {Observable<HttpEvent<AttachmentOutdateResponse>>}
   */
  patchOutdateFile$(
    attachmentId: string,
    request: AttachmentOutdateRequest,
  ): Observable<HttpEvent<AttachmentOutdateResponse>> {
    const url = `/Files/${attachmentId}/Outdate`;

    return this.request$<AttachmentOutdateResponse>('PATCH', url, request);
  }

  /**
   * Uploads an inline file for the WYSIWYG editor to the blob
   *
   * @param {File} upload
   * @return {Observable<HttpEvent<InlineFileDto>>}
   */
  postFilesInline$(upload: File): Observable<HttpEvent<InlineFileDto>> {
    const url = '/Files/Inline';

    const body = obj2fd({
      upload,
    });

    return this.request$<InlineFileDto>('POST', url, body);
  }

  /**
   * Gets a list of all ratings
   *
   * @return {Observable<HttpEvent<RatingListResponse>>}
   */
  getRatings$(): Observable<HttpEvent<RatingListResponse>> {
    const url = '/Lookup/Ratings';

    return this.request$<RatingListResponse>('GET', url, null);
  }

  /**
   * Gets a list of all gates
   *
   * @return {Observable<HttpEvent<GateListResponse>>}
   */
  getGates$(): Observable<HttpEvent<GateListResponse>> {
    const url = '/Lookup/Gates';

    return this.request$<GateListResponse>('GET', url, null);
  }

  /**
   * Checks the current health (availabilty) of the application
   *
   * @return {Observable<HttpEvent<string>>}
   */
  getHealth$(): Observable<HttpEvent<string>> {
    const url = '/Health';

    return this.request$<string>('GET', url, null);
  }

  /**
   * Imports a list of users and units
   *
   * @return {Observable<HttpEvent<ImportUserAndUnitResponse>>}
   */
  getImportsUsersAndUnits$(): Observable<HttpEvent<ImportUserAndUnitResponse>> {
    const url = '/Imports/UsersAndUnits';

    return this.request$<ImportUserAndUnitResponse>('GET', url, null);
  }

  /**
   * Creates a new level
   *
   * @param {number} projectId
   * @param {LevelCreateRequest} request
   * @return {Observable<HttpEvent<LevelCreateResponse>>}
   */
  postProjectLevel$(projectId: number, request: LevelCreateRequest): Observable<HttpEvent<LevelCreateResponse>> {
    const url = `/Projects/${projectId}/Levels`;

    return this.request$<LevelCreateResponse>('POST', url, request);
  }

  /**
   * Deletes a specific project level and its children, if any
   *
   * @param {number} projectId
   * @param {number} levelId
   */
  deleteProjectLevel$(projectId: number, levelId: number): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/Levels/${levelId}`;

    return this.request$<void>('DELETE', url, null);
  }

  /**
   * Gets a flat list of levels for the current project and, if given, the current parent level
   */
  getProjectLevels$(projectId: number, parentLevelId?: number): Observable<HttpEvent<LevelListResponse>> {
    const url = `/Projects/${projectId}/Levels${parentLevelId ? `/${parentLevelId}` : ''}`;

    return this.request$<LevelListResponse>('GET', url, null);
  }

  /**
   * Gets infos about a level
   */
  getLevelInformation$(levelId?: number): Observable<HttpEvent<LevelInfoResponse>> {
    const url = `/Levels/Info/${levelId}`;

    return this.request$<LevelInfoResponse>('GET', url, null);
  }

  /**
   *
   */
  getProjectStructure$(projectId: number): Observable<HttpEvent<ProjectStructureResponse>> {
    const url = `/Projects/${projectId}/Structure`;

    return this.request$<ProjectStructureResponse>('GET', url, null);
  }

  /**
   * Updates a specific project level
   *
   * @param {number} projectId
   * @param {number} levelId
   * @param {LevelPlanningUpdateRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  updateProjectLevel$(
    projectId: number,
    levelId: number,
    request: LevelPlanningUpdateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/Levels/${levelId}`;

    return this.request$<void>('PATCH', url, request);
  }

  /**
   * Swaps the to provided levels' order
   *
   * @param {number} projectId
   * @param {LevelSwapRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  swapProjectLevels$(projectId: number, request: LevelSwapRequest): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/Levels/Swap`;

    return this.request$<void>('PATCH', url, request);
  }

  assignWorkPackageToUser$(
    projectId: number,
    workPackageId: number,
    userId: string,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/${userId}`;

    return this.request$('PUT', url, null);
  }

  /**
   * Saves the project structure preset for the current user
   *
   * @param {ProjectStructurePresetUpdateRequest} request
   * @return {Observable<HttpEvent<UserSettingDto>>}
   */
  putProjectStructurePreset$(
    request: ProjectStructurePresetUpdateRequest,
  ): Observable<HttpEvent<UserSettingDto>> {
    const url = '/Projects/Levels/Preset';

    return this.request$<UserSettingDto>('PUT', url, request);
  }

  /**
   * Gets a page of a list of filtered projects
   *
   * @return {Observable<HttpEvent<ProjectListResponse>>}
   */
  getProjects$(options: ListQueryOptions): Observable<HttpEvent<ProjectListResponse>> {
    const url = '/Projects';

    const queryParameters = options.toHttpParams();

    return this.request$<ProjectListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Creates a new project
   *
   * @param {ProjectCreateRequest} request
   * @return {Observable<HttpEvent<ProjectCreateResponse>>}
   */
  postProject$(request: ProjectCreateRequest): Observable<HttpEvent<ProjectCreateResponse>> {
    const url = '/Projects';

    return this.request$<ProjectCreateResponse>('POST', url, request);
  }

  /**
   *
   * @param projectId
   * @param workPackageId
   * @param questionId
   * @param request
   *
   * @return {Observable<HttpEvent<ReopenCreateResponse>>}
   */
  postWorkPackageQuestionReopen$(
    projectId: number,
    workPackageId: number,
    questionId: number,
    request: ReopenCreateRequest,
  ): Observable<HttpEvent<ReopenCreateResponse>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Questions/${questionId}/Reopen`;

    return this.request$<ReopenCreateResponse>('POST', url, request);
  }

  /**
   *
   * @param projectId
   * @param workPackageId
   * @param questionId
   * @param request
   *
   * @return {Observable<HttpEvent<ConclusionCreateResponse>>}
   */
  postWorkPackageQuestionConclude$(
    projectId: number,
    workPackageId: number,
    questionId: number,
    request: ConclusionCreateRequest,
  ): Observable<HttpEvent<ConclusionCreateResponse>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Questions/${questionId}/Conclude`;

    return this.request$<ConclusionCreateResponse>('POST', url, request);
  }

  /**
   *
   * @param projectId
   * @param workPackageId
   * @param questionId
   *
   * @return {Observable<HttpEvent<ReplyListResponse>>}
   */
  getQuestionReplies$(
    projectId: number,
    workPackageId: number,
    questionId: number,
  ): Observable<HttpEvent<ReplyListResponse>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Questions/${questionId}/Replies`;

    return this.request$<ReplyListResponse>('GET', url, null);
  }

  /**
   *
   * @param projectId
   * @param workPackageId
   * @param questionId
   * @param request
   *
   * @return {Observable<HttpEvent<ReplyCreateResponse>>}
   */
  postWorkPackageQuestionReply$(
    projectId: number,
    workPackageId: number,
    questionId: number,
    request: ReplyCreateRequest,
  ): Observable<HttpEvent<ReplyCreateResponse>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Questions/${questionId}/Reply`;

    return this.request$<ReplyCreateResponse>('POST', url, request);
  }

  /**
   * Creates a new Question
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {QuestionCreateRequest} request
   *
   * @return {Observable<HttpEvent<QuestionCreateResponse>>}
   */
  postWorkPackageQuestion$(
    projectId: number,
    workPackageId: number,
    request: QuestionCreateRequest,
  ): Observable<HttpEvent<QuestionCreateResponse>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Questions`;

    return this.request$<QuestionCreateResponse>('POST', url, request);
  }

  /**
   * Updates an existing Question
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {QuestionUpdateRequest} request
   *
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageQuestion$(
    projectId: number,
    workPackageId: number,
    request: QuestionUpdateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Questions`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates an existing project
   *
   * @param {number} projectId
   * @param {ProjectUpdateRequest} request
   * @return {Observable<HttpEvent<void>>} - Returns the updated project
   */
  putProjectByProjectId$(projectId: number, request: ProjectUpdateRequest): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Archive an existing project
   *
   * @param {number} projectId
   * @return {Observable<HttpEvent<void>>} - Returns the archived project
   */
  archiveProject$(projectId: number): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/Archive`;

    return this.request$<void>('PUT', url);
  }

  /**
   * Unarchive an existing project
   *
   * @param {number} projectId
   * @return {Observable<HttpEvent<void>>} - Returns the unarchived project
   */
  unarchiveProject$(projectId: number): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/Unarchive`;

    return this.request$<void>('PUT', url);
  }

  /**
   * Exports an existing project
   *
   * @param {number} projectId
   * @return {Observable<HttpEvent<void>>} - Returns the exported project as excel file
   */
  exportProject$(projectId: number): Observable<HttpEvent<FileDownloadResponse>> {
    const url = `/Projects/${projectId}/Export`;

    return this.request$<FileDownloadResponse>('GET', url);
  }

  /**
   * Updates an existing project from SAP
   *
   * @param {number} projectId
   * @param {ProjectUpdateFromSapRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  patchProjectByProjectId$(
    projectId: number,
    request: ProjectUpdateFromSapRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}`;

    return this.request$<void>('PATCH', url, request);
  }

  /**
   * Gets a list of open workPackage names by project id
   *
   * @param {number} projectId
   * @return {Observable<HttpEvent<string[]>>}
   */
  getOpenWorkPackageTitles$(projectId: number): Observable<HttpEvent<string[]>> {
    const url = `/Projects/${projectId}/OpenWorkPackageTitles`;

    return this.request$<string[]>('GET', url, null);
  }

  /**
   * Gets a ProjectViewResponse by project id
   * NOTE: "General" will fall away, once the GET endpoint for receiving a single project
   * is not used any more.
   *
   * @param {number} projectId
   * @return {Observable<HttpEvent<ProjectViewResponse>>}
   */
  getProjectView$(projectId: number): Observable<HttpEvent<ProjectViewResponse>> {
    const url = `/Projects/${projectId}`;

    return this.request$<ProjectViewResponse>('GET', url, null);
  }

  /**
   * Gets a ProjectScopeViewResponse by project id
   *
   * @param {number} projectId
   * @return {Observable<HttpEvent<ProjectScopeViewResponse>>}
   */
  getProjectScopeView$(projectId: number): Observable<HttpEvent<ProjectScopeViewResponse>> {
    const url = `/Projects/${projectId}/Scope`;

    return this.request$<ProjectScopeViewResponse>('GET', url, null);
  }

  /**
   * Gets a ProjectActivityView by project id and the filtering of the Paginator
   *
   * @param {number} projectId
   * @param {number} pageNumber
   * @param {number} pageSize
   * @param {ActivityFetchType} [activityFetchType]
   * @return {Observable<HttpEvent<ProjectActivityViewResponse>>}
   */
  getProjectActivityView$(
    projectId: number,
    pageNumber?: number,
    pageSize?: number,
    activityFetchType?: ActivityFetchType,
  ): Observable<HttpEvent<ProjectActivityViewResponse>> {
    const url = `/Projects/${projectId}/Activity`;

    const options = new ListQueryOptions(
      pageNumber,
      pageSize,
    );

    const queryParameters = options.toHttpParams()
      .set('activityFetchType', activityFetchType);

    return this.request$<ProjectActivityViewResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a ProjectActivityView by project id and the filtering of the Paginator
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {number} pageNumber
   * @param {number} pageSize
   * @param {ActivityFetchType} [activityFetchType]
   * @return {Observable<HttpEvent<ProjectActivityViewResponse>>}
   */
  getWorkPackageActivityView$(
    projectId: number,
    workPackageId: number,
    pageNumber?: number,
    pageSize?: number,
    activityFetchType?: ActivityFetchType,
  ): Observable<HttpEvent<ProjectActivityViewResponse>> {
    const url = `/Projects/${projectId}/Activity`;

    const options = new ListQueryOptions(
      pageNumber,
      pageSize,
    );

    const queryParameters = options.toHttpParams()
      .set('workPackageId', workPackageId)
      .set('activityFetchType', activityFetchType);

    return this.request$<ProjectActivityViewResponse>('GET', url, null, queryParameters);
  }

  getCurrentAdditionalSuppliers$(
    projectId: number,
    workPackageId: number,
  ): Observable<HttpEvent<WorkpackageSupplierSettingsDto[]>> {
    const url = `/Projects/${projectId}/WorkPackages/CurrentAdditionalSuppliers`;

    const queryParameters = new CustomHttpParams({
      workPackageId,
    });

    return this.request$<WorkpackageSupplierSettingsDto[]>('GET', url, null, queryParameters);
  }

  getAdditionalSuppliers$(
    projectId: number,
    workPackageId: number,
    contractSupplierUnitId: number,
    excludedPairIds: string,
  ): Observable<HttpEvent<ContractDto[]>> {
    const url = `/Projects/${projectId}/WorkPackages/AdditionalSuppliers`;

    const queryParameters = new CustomHttpParams({
      workPackageId,
      contractSupplierUnitId,
      excludedPairIds,
    });

    return this.request$<ContractDto[]>('GET', url, null, queryParameters);
  }

  getAvailableContracts$(
    projectId: number,
    wokpackageId?: number,
    wpCreatorId?: string,
  ): Observable<ContractDtoModel[]> {
    let url = `/projects/${projectId}/AvailableContracts`;
    if (wokpackageId !== undefined) {
      url += `/${wokpackageId}`;
    }
    if (wpCreatorId !== undefined) {
      url += `/${wpCreatorId}`;
    }

    return this.request$<any>('GET', url).pipe(
      filter((event) => event.type === HttpEventType.Response),
      // eslint-disable-next-line arrow-body-style
      map((response: HttpResponse<any>) => {
        const list: ContractDtoModel[] = [];
        response.body.forEach((item: any) => {
          list.push(ContractDtoModel.fromJSON(item));
        });
        return list;
      }),
    );
  }

  /**
   * Get the latest project import file's name for a given projectId
   *
   * @param {number} projectId
   * @return {Observable<HttpEvent<string>>} - Returns the latest file name, if any
   */
  getLatestImportFileForManuallyUpdate$(projectId: number): Observable<HttpEvent<string>> {
    const url = `/Projects/LatestImportFileForUpdate/${projectId}`;

    return this.request$<string>('GET', url);
  }

  /**
   * Get the latest project import file's name for creating a new project
   * @return {Observable<HttpEvent<string>>} - Returns the latest file name, if any
   */
  getLatestImportFileForCreateNewProject$(): Observable<HttpEvent<string>> {
    const url = '/Projects/LatestImportFileForCreate';

    return this.request$<string>('GET', url);
  }

  /**
   * Returns a link for downloading the created export file down.
   *
   * @return {Observable<HttpEvent<FileDownloadResponse>>}
   */
  getProjectExportList$(options: WorkPackageListOptions): Observable<HttpEvent<FileDownloadResponse>> {
    const url = '/WorkPackages/ExportList';

    const queryParameters = options.toHttpParams();

    return this.request$<FileDownloadResponse>('GET', url, null, queryParameters);
  }

  /**
   * Imports a project file and returns a list of projects including their structure
   *
   * @param {File} file
   * @param {string} [sapNumber]
   * @return {Observable<HttpEvent<ProjectImportListResponse>>}
   */
  postProjectsImport$(file: File | null, sapNumber?: string): Observable<HttpEvent<ProjectImportListResponse>> {
    const url = '/Projects/Import';

    const body = obj2fd({
      file,
      sapNumber,
    });

    return this.request$<ProjectImportListResponse>('POST', url, body);
  }

  /**
   * Imports a project file and returns the project specified by SapNumber in the request
   * @param {File} file
   * @param {string} sapNumber
   * @return {Observable<HttpEvent<ProjectImportListDto>>}
   */
  postProjectsParseImportFile$(
    file: File | null,
    sapNumber: string,
    projectId: number,
  ): Observable<HttpEvent<ProjectImportListDto>> {
    const url = '/Projects/ParseImportFile';

    const body = obj2fd({
      file,
      sapNumber,
      projectId,
    });

    return this.request$<ProjectImportListDto>('POST', url, body);
  }

  /**
   * Gets a list of all project types
   *
   * @return {Observable<HttpEvent<ProjectTypeListResponse>>}
   */
  getProjectTypes$(): Observable<HttpEvent<ProjectTypeListResponse>> {
    const url = '/Lookup/ProjectTypes';

    return this.request$<ProjectTypeListResponse>('GET', url, null);
  }

  getUnitCostCentersByUnitId$(unitId: string): Observable<HttpEvent<CostCenterDto[]>> {
    if (!unitId) {
      console.warn('getUnitCostCentersByUnitId$ called without unitId');
    }
    const url = `/Lookup/UnitCostCenters`;

    const queryParameters = new CustomHttpParams({unitId});

    return this.request$<CostCenterDto[]>('GET', url, null, queryParameters);
  }

  getUnitActivityTypesByCostCenter$(costCenter: string): Observable<HttpEvent<ActivityTypeDto[]>> {
    if (!costCenter) {
      console.warn('getUnitActivityTypesByCostCenter called without costCenter');
    }
    const url = `/Lookup/UnitActivityTypes`;

    const queryParameters = new CustomHttpParams({costCenter});

    return this.request$<ActivityTypeDto[]>('GET', url, null, queryParameters);
  }

  getUnitActivityTypesByCostCenterAndUnitId$(unitId: string, costCenter: string): Observable<HttpEvent<ActivityTypeDto[]>> {
    if (!costCenter) {
      console.warn('getUnitActivityTypesByCostCenter called without costCenter');
    }
    const url = `/Lookup/UserUnitAssignmentActivityType`;

    const queryParameters = new CustomHttpParams({unitId, costCenter});

    return this.request$<ActivityTypeDto[]>('GET', url, null, queryParameters);
  }

  /**
   * get a list of activity types for a supplierunit
   */
  getSupplierActivityTypes$(supplierUnitId: string): Observable<HttpEvent<ActivityTypeDto[]>> {
    const url = '/Lookup/SupplierActivityTypes';

    const queryParameters = new CustomHttpParams({supplierUnitId});

    return this.request$<ActivityTypeDto[]>('GET', url, null, queryParameters);
  }

  /**
   * Gets a limited list of projects the unit coordinator has access to.
   */
  getProjectsForUnitCoordinator$(
    requestOptions: AvailableRequestRequestOptions,
  ): Observable<HttpEvent<AvailalbeProjectListResponse>> {
    const url = '/Projects/AvailableProjects';

    const queryParameters = new CustomHttpParams({
      limit: requestOptions.limit,
      filterString: requestOptions.filterString,
    });

    return this.request$<AvailalbeProjectListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a limited list of projects the user has access to.
   */
  getProjectSelectors$(
    requestOptions: SelectorRequestOptions,
  ): Observable<HttpEvent<ProjectSelectorListResponse>> {
    const url = '/Projects/ProjectSelectorProjects';

    const queryParameters = new CustomHttpParams({
      limit: requestOptions.limit,
      filterString: requestOptions.filterString,
      onlySubmitterProjects: requestOptions.onlySubmitterProjects,
    });

    return this.request$<ProjectSelectorListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a list of all coordinators of a given Unit for Confirming WP
   * @return {Observable<HttpEvent<UnitCoordinatorResponse>>}
   */
  getUnitCoordinatorsForConfirmingWP$(unitId: number): Observable<HttpEvent<UserSlimDto[]>> {
    const url = `/Units/${unitId}/UnitCoordinatorForConfirmingWP`;

    return this.request$<UserSlimDto[]>('GET', url, null);
  }

  /**
   * Gets a list of all coordinators of a given Unit
   * @return {Observable<HttpEvent<UnitCoordinatorResponse>>}
   */
  getUnitCoordinators$(unitId: number): Observable<HttpEvent<UserSlimDto[]>> {
    const url = `/Units/${unitId}/UnitCoordinators`;

    return this.request$<UserSlimDto[]>('GET', url, null);
  }

  /**
   * Gets a list of all units
   * @return {Observable<HttpEvent<UnitListResponse>>}
   */
  getUnits$(options?: ListQueryOptions): Observable<HttpEvent<UnitListResponse>> {
    const url = '/Units';

    const queryParameters = (options ? options.toHttpParams() : undefined);

    return this.request$<UnitListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a list of L1 units
   * @return {Observable<HttpEvent<UnitSlimDtoWithoutCoordinatorDto>>}
   */
  getL1Units$(): Observable<HttpEvent<UnitSlimDtoWithoutCoordinatorDto[]>> {
    const url = '/Units/L1Units';

    return this.request$<UnitSlimDtoWithoutCoordinatorDto[]>('GET', url, null);
  }

  /**
   * Gets a list of colors for a L1 unit
   * @return
   */
  getColorsByL1Unit$(unitId: number): Observable<HttpEvent<BackgroundColorResponse[]>> {
    const url = `/Units/${unitId}/BackgroundColors`;

    return this.request$<BackgroundColorResponse[]>('GET', url, null);
  }

  /**
   * Updates existing background color definitions
   * Response and request are the same
   */
  putColors$(unitId: number, request: BackgroundColorsRequest): Observable<HttpEvent<void>> {
    const url = `/Units/${unitId}/BackgroundColors`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Gets the view response of the units view in the administration section
   * @return {Observable<HttpEvent<UnitsViewResponse>>}
   */
  getUnitsViewResponse$(): Observable<HttpEvent<UnitsViewResponse>> {
    const url = '/Units/UnitsViewResponse';

    return this.request$<UnitsViewResponse>('GET', url, null);
  }

  /**
   * Gets a view response of a unit
   *
   * @param {number} unitId
   * @return {Observable<HttpEvent<UnitViewResponse>>}
   */
  getUnitViewResponseByUnitId$(unitId: number): Observable<HttpEvent<UnitViewResponse>> {
    const url = `/Units/${unitId}/UnitViewResponse`;

    return this.request$<UnitViewResponse>('GET', url, null);
  }

  /**
   * Gets a list of all non-juman resources
   * @return {Observable<HttpEvent<NonHumanResourceListResponse>>}
   */
  getNonHumanResources$(options?: ListQueryOptions): Observable<HttpEvent<NonHumanResourceListResponse>> {
    const url = '/NonHumanResources';

    const queryParameters = (options ? options.toHttpParams() : undefined);

    return this.request$<NonHumanResourceListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets the view response of the non-human resource view in the administration section
   * @return {Observable<HttpEvent<NonHumanResourceViewResponse>>}
   */
  getNonHumanResourcesViewResponse$(id: string): Observable<HttpEvent<NonHumanResourceViewResponse>> {
    const url = `/NonHumanResources/${id}/NonHumanViewResponse`;

    return this.request$<NonHumanResourceViewResponse>('GET', url, null);
  }

  /**
   * Creates a new non-human resource
   *
   * @param {UnitCreateRequest} request
   * @return {Observable<HttpEvent<NonHumanResourceCreateResponse>>}
   */
  postNonHumanResource$(
    request: NonHumanResourceCreateRequest,
  ): Observable<HttpEvent<NonHumanResourceCreateResponse>> {
    const url = '/NonHumanResources';

    return this.request$<NonHumanResourceCreateResponse>('POST', url, request);
  }

  /**
   * Updates an existing non-human resource
   *
   * @param {string} nonHumanResourceId
   * @param {NonHumanResourceUpdateRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putNonHumanResource$(
    nonHumanResourceId: string,
    request: NonHumanResourceUpdateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/NonHumanResources/${nonHumanResourceId}`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Gets a single NHR by id.
   *
   * @param {string} userId
   * @return {Observable<HttpEvent<UserSlimDto>>}
   */
  getNonHumanResourceById$(nonHumanResourceId: string): Observable<HttpEvent<NonHumanResourceDto>> {
    const url = `/NonHumanResources/${nonHumanResourceId}/ById`;

    return this.request$<NonHumanResourceDto>('GET', url, null);
  }

  /**
   * Creates a new unit
   *
   * @param {UnitCreateRequest} request
   * @return {Observable<HttpEvent<UnitCreateResponse>>}
   */
  postUnit$(request: UnitCreateRequest): Observable<HttpEvent<UnitCreateResponse>> {
    const url = '/Units';

    return this.request$<UnitCreateResponse>('POST', url, request);
  }

  /**
   * Gets a list of all units
   *
   * @return {Observable<HttpEvent<UnitSelectorListResponse>>}
   */
  getUnitSelectors$(
    requestOptions: UnitSelectorRequestOptions,
  ): Observable<HttpEvent<UnitSelectorListResponse>> {
    const url = '/Units/UnitSelectorUnits';

    const queryParameters = new CustomHttpParams({
      unitFetchType: requestOptions.unitFetchType,
      limit: requestOptions.limit,
      includeChildren: requestOptions.includeChildren,
      filterString: requestOptions.filterString,
      date: requestOptions.date,
    });

    return this.request$<UnitSelectorListResponse>('GET', url, null, queryParameters);
  }

  getAvailableUnitSelectors$(
    requestOptions: UnitSelectorAvailableRequestOptions,
  ): Observable<HttpEvent<UnitSelectorListResponse>> {
    const url = `/Projects/${requestOptions.projectId}/WorkPackages/AvailableUnitsForWorkPackage`;

    const queryParameters = new CustomHttpParams({
      ...requestOptions,
    });

    return this.request$<UnitSelectorListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a single unit by ID
   *
   * @param {number} unitId
   * @return {Observable<HttpEvent<UnitDto>>}
   */
  getUnitByUnitId$(unitId: number): Observable<HttpEvent<UnitDto>> {
    const url = `/Units/${unitId}`;

    return this.request$<UnitDto>('GET', url, null);
  }

  /**
   * Gets a single (slim) unit by ID
   */
  getUnitSlimByUnitId$(unitId: number): Observable<HttpEvent<UnitSlimDto>> {
    const url = `/Units/Slim/${unitId}`;

    return this.request$<UnitSlimDto>('GET', url, null);
  }

  /**
   * Gets a list of defects for a unit.
   * If no unit id is provided, a default list of defects is provided.
   *
   * @param unitId
   */
  getUnitDefects$(unitId?: number): Observable<HttpEvent<DefectListResponse>> {
    const url = unitId != null ? `/Units/${unitId}/Defects` : '/Units/Defects';

    return this.request$<DefectListResponse>('GET', url, null);
  }

  /**
   * Gets a list of the default non productive categories a unit has.
   *
   * @return {Observable<HttpEvent<DefaultNonProductiveCategoryResponse[]>>}
   */
  getDefaultNonProductiveCategories$(): Observable<HttpEvent<DefaultNonProductiveCategoryResponse[]>> {
    const url = '/Units/DefaultNonProductiveCategories';

    return this.request$<DefaultNonProductiveCategoryResponse[]>('GET', url, null);
  }

  /**
   * Gets a unit's timesheet export template.
   * If no unit id is provided, a default template is provided.
   *
   * @param unitId
   */
  getUnitTimesheetExportTemplate$(unitId?: number): Observable<HttpEvent<TimesheetExportTemplateResponse>> {
    const url = unitId != null ? `/Units/${unitId}/TimesheetExportTemplate` : '/Units/TimesheetExportTemplate';

    return this.request$<TimesheetExportTemplateResponse>('GET', url, null);
  }

  /**
   * Updates an existing unit
   *
   * @param {number} unitId
   * @param {UnitUpdateRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putUnit$(unitId: number, request: UnitUpdateRequest): Observable<HttpEvent<void>> {
    const url = `/Units/${unitId}`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Disables the given unit
   *
   * @param {number} unitId
   * @return {Observable<HttpEvent<void>>}
   */
  disableUnit$(unitId: number): Observable<HttpEvent<void>> {
    const url = `/Units/${unitId}/Disable`;

    return this.request$<void>('PUT', url);
  }

  /**
   * Gets a page of a list of filtered users
   */
  getUsers$(options: UserListQueryOptions): Observable<HttpEvent<UserListViewResponse>> {
    const url = '/Users';

    const queryParameters = options.toHttpParams();

    return this.request$<UserListViewResponse>('GET', url, null, queryParameters);
  }

  getAvailableResourceUsers$(
    options: UserResourceListQueryOptions,
  ): Observable<HttpEvent<AvailableResourcesResponse>> {
    const url = '/Users/AvailableResources';

    const request = options.generateRequestModel();

    return this.request$<AvailableResourcesResponse>('PUT', url, request);
  }

  getResponsibleUserCapacity$(
    projectId: number,
    workPackageId: number,
    year: number,
    week: number,
    numberOfWeeks: number,
    userId: string,
  ): Observable<HttpEvent<ResponsibleUserDto>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/ResourcePlanning/${year}/${week}/NumberOfWeeks/${numberOfWeeks}/ResponsibleUser/${userId}`;

    return this.request$<ResponsibleUserDto>('GET', url);
  }

  getResponsibleResourceCapacity$(
    projectId: number,
    workPackageId: number,
    year: number,
    week: number,
    numberOfWeeks: number,
    userId: string,
  ): Observable<HttpEvent<ResponsibleResourceDto>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/ResourcePlanning/${year}/${week}/NumberOfWeeks/${numberOfWeeks}/NonHumanResource/${userId}`;

    return this.request$<ResponsibleResourceDto>('GET', url);
  }

  /**
   * Creates a new user
   *
   * @param {UserCreateRequest} request
   * @return {Observable<HttpEvent<UserCreateResponse>>}
   */
  postUser$(request: UserCreateRequest): Observable<HttpEvent<UserCreateResponse>> {
    const url = '/Users';

    return this.request$<UserCreateResponse>('POST', url, request);
  }

  /**
   * Gets the view for users in the administration section
   *
   * @return {Observable<HttpEvent<UsersViewResponse>>}
   */
  getUsersView$(): Observable<HttpEvent<UsersViewResponse>> {
    const url = '/Users/UsersViewResponse';

    return this.request$<UsersViewResponse>('GET', url);
  }

  /**
   * Gets a single user by id.
   *
   * @param {string} userId
   * @return {Observable<HttpEvent<UserSlimDto>>}
   */
  getUserById$(userId: string): Observable<HttpEvent<UserSlimDto>> {
    const url = `/Users/${userId}/ById`;

    return this.request$<UserSlimDto>('GET', url, null);
  }

  /**
   * Gets a single user by email address
   *
   * @param {string} email
   * @return {Observable<HttpEvent<UserDto>>}
   */
  getUserByEmail$(email: string): Observable<HttpEvent<UserDto>> {
    const url = `/Users/${email}`;

    return this.request$<UserDto>('GET', url, null);
  }

  /**
   * Gets a view for a user by email address
   *
   * @param {string} email
   * @return {Observable<HttpEvent<UserViewResponse>>}
   *
   * @TODO Check if getting user by EMAIL instead of ID is really required
   */
  getUserViewByEmail$(email: string): Observable<HttpEvent<UserViewResponse>> {
    const url = `/Users/${email}/UserViewResponse`;

    return this.request$<UserViewResponse>('GET', url, null);
  }

  /**
   * Get User Recipiens of a Workpackage
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageStatus} to
   * @return {Observable<HttpEvent<WorkPackageTransitionInformationResponse>>}
   */
  getWorkPackageTransitionInformation$(
    projectId: number,
    workPackageId: number,
    to: WorkPackageStatus,
  ): Observable<HttpEvent<WorkPackageTransitionInformationResponse>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/TransitionInformation`;

    const queryParameters = new CustomHttpParams({
      newStatus: to,
    });

    return this.request$<WorkPackageTransitionInformationResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a single AAD user by email address
   *
   * @param {string} email
   * @return {Observable<HttpEvent<UserDto>>}
   */
  getAdUserByEmail$(email: string): Observable<HttpEvent<UserDto>> {
    const url = `/Users/${email}/AADUser`;

    return this.request$<UserDto>('GET', url, null);
  }

  /**
   * Updates an existing user
   *
   * @param {string} userId
   * @param {UserUpdateRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putUser$(userId: string, request: UserUpdateRequest): Observable<HttpEvent<void>> {
    const url = `/Users/${userId}`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Gets the currently authenticated user
   *
   * @return {Observable<HttpEvent<UserDto>>}
   */
  getCurrentUser$(): Observable<HttpEvent<UserDto>> {
    const url = '/Users/Me';

    return this.request$<UserDto>('GET', url, null);
  }

  /**
   * Gets another user
   *
   * @return {Observable<HttpEvent<UserDto>>}
   */
  getSubstituteUser$(userId: string): Observable<HttpEvent<UserDto>> {
    const url = '/Users/Me';
    const headers = new HttpHeaders({
      Mystique: userId,
    });

    return this.request$<UserDto>('GET', url, undefined, undefined, headers);
  }

  /**
   * Updates the timecard of a user.
   *
   * @param userId
   * @param year
   * @param week
   * @param request
   */
  putTimecard$(
    userId: string,
    year: number,
    week: number,
    request: TimecardSaveRequest,
  ): Observable<HttpEvent<FileDownloadResponse>> {
    const url = `/Users/${userId}/Timecard/${year}/${week}`;

    return this.request$<FileDownloadResponse>('PUT', url, request);
  }

  /**
   * Cancels the planning of a user.
   *
   * @param userId
   * @param year
   * @param week
   * @param request
   */
  cancelUserPlanning$(userId: string, request: PlanningSaveRequest): Observable<HttpEvent<void>> {
    const url = `/Users/${userId}/Planning/Cancel`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Confirms the planning of a user.
   *
   * @param userId
   * @param year
   * @param week
   * @param request
   */
  confirmUserPlanning$(userId: string, request: PlanningSaveRequest): Observable<HttpEvent<void>> {
    const url = `/Users/${userId}/Planning/Confirm`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Saves the planning of a NHR.
   *
   * @param nonHumanResourceId
   * @param year
   * @param week
   * @param request
   */
  saveNonHumanResourcePlanning$(
    nonHumanResourceId: string,
    request: PlanningSaveRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/NonHumanResource/${nonHumanResourceId}/Planning/Save`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Confirms the planning of a NHR.
   *
   * @param nonHumanResourceId
   * @param year
   * @param week
   * @param request
   */
  confirmNonHumanResourcePlanning$(
    nonHumanResourceId: string,
    request: PlanningSaveRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/NonHumanResource/${nonHumanResourceId}/Planning/Confirm`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Cancels the planning of a NHR.
   *
   * @param nonHumanResourceId
   * @param year
   * @param week
   * @param request
   */
  cancelNonHumanResourcePlanning$(
    nonHumanResourceId: string,
    request: PlanningSaveRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/NonHumanResource/${nonHumanResourceId}/Planning/Cancel`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Gets a timecard of a particular week of a user.
   *
   * @param userId
   * @param year
   * @param week
   */
  getTimecard$(
    userId: string,
    year: number,
    week: number,
    isMyTimecard: boolean,
    timecardFilters?: CollappFilterDropdownListRadioSetting[],
  ): Observable<HttpEvent<TimecardViewResponse>> {
    const url = `${environment.apiEndpoint}/Users/${userId}/Timecard/${year}/${week}`;

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('isMyTimecard', isMyTimecard);

    if (timecardFilters && timecardFilters.length) {
      queryParameters = this.makeHeaderFilterRequestParams(timecardFilters, queryParameters);
    }

    return this.doHttpRequest$<TimecardViewResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets planning of a particular period of a user.
   */
  getUserPlanning$(
    userId: string,
    isoWeekYear: number,
    isoWeek: number,
    numberOfWeeks?: number,
    headerFilters?: CollappFilterDropdownListRadioSetting[],
  ): Observable<HttpEvent<UserPlanningViewResponse>> {
    const url = `${environment.apiEndpoint}/Users/${userId}/Planning/${isoWeekYear}/${isoWeek}`;

    let queryParameters = new HttpParams();
    if (numberOfWeeks) {
      queryParameters = queryParameters.set('numberOfWeeks', numberOfWeeks.toString());
    }

    if (headerFilters && headerFilters.length) {
      queryParameters = this.makeHeaderFilterRequestParams(headerFilters, queryParameters);
    }

    return this.doHttpRequest$<UserPlanningViewResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets planning of a particular period of a NHR.
   */
  getNonHumanResourcePlanning$(
    nonHumanResourceId: string,
    isoWeekYear: number,
    isoWeek: number,
    numberOfWeeks?: number,
    headerFilters?: CollappFilterDropdownListRadioSetting[],
  ): Observable<HttpEvent<NonHumanResourcePlanningViewResponse>> {
    const url = `${environment.apiEndpoint}/NonHumanResource/${nonHumanResourceId}/Planning/${isoWeekYear}/${isoWeek}`;

    let queryParameters = new HttpParams();
    if (numberOfWeeks) {
      queryParameters = queryParameters.set('numberOfWeeks', numberOfWeeks.toString());
    }

    if (headerFilters && headerFilters.length) {
      queryParameters = this.makeHeaderFilterRequestParams(headerFilters, queryParameters);
    }

    return this.doHttpRequest$<NonHumanResourcePlanningViewResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets planning of a particular week of a unit.
   */
  getUnitPlanning$(
    unitId: number,
    request: UnitPlanningViewRequest,
  ): Observable<HttpEvent<UnitPlanningViewResponse>> {
    const url = `/Planning/Unit/${unitId}`;

    return this.request$<UnitPlanningViewResponse>('PUT', url, request);
  }

  /**
   * Gets planning of a particular time period of a project.
   */
  getProjectPlanning$(
    projectId: number,
    request: ProjectPlanningViewRequestModel,
  ): Observable<HttpEvent<ProjectPlanningViewResponse>> {
    const url = `${environment.apiEndpoint}/projects/${projectId}/planning`;
    const queryParameters = request.toHttpParams();

    return this.doHttpRequest$<ProjectPlanningViewResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a list of users available in a given timeframe for the unit planning.
   */
  getUserPlanningResources$(
    unitId: number,
    name: string,
    isoWeekYear: number,
    isoWeek: number,
    numberOfWeeks: number,
    isForWpCreator?: boolean,
  ): Observable<HttpEvent<UnitPlanningUserListViewResponse>> {
    const url = `/Planning/${unitId}/PlanningResources/`;

    const queryParameters = new CustomHttpParams({
      name,
      year: isoWeekYear,
      startWeek: isoWeek,
      weekNumber: numberOfWeeks,
      isForWpCreator,
    });

    return this.request$<UnitPlanningUserListViewResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets Weeks by user year week and amount.
   *
   * @param userId
   * @param year
   * @param week
   * @param amount
   */
  getTimecardWeeks$(
    userId: string,
    year: number,
    week: number,
    amount: number,
  ): Observable<HttpEvent<TimeTrackingWeekNavigatorViewResponse>> {
    const url = `/Users/${userId}/Timecard/WeekNavigator/${year}/${week}/NumberOfWeeks/${amount}`;

    return this.request$<TimeTrackingWeekNavigatorViewResponse>('GET', url, null);
  }

  /**
   * Updates the states of users timecards.
   */
  putTimecardStatus$(
    year: number,
    week: number,
    request: TimecardStatusUpdateRequest,
  ): Observable<HttpEvent<FileDownloadResponse>> {
    const url = `/Users/Timecard/${year}/${week}/UpdateTimecardStatus`;

    return this.request$<FileDownloadResponse>('PUT', url, request);
  }

  /**
   * Gets a timecard of a particular week of a user.
   */
  getTimesheet$(
    unitId: number,
    year: number,
    week: number,
    withCrossUtilize: boolean,
    isSubmitterView: boolean | undefined,
  ): Observable<HttpEvent<TimesheetViewResponse>> {
    const url = `/Units/${unitId}/Timesheets/${year}/${week}`;

    const params = new CustomHttpParams({
      withCrossUtilize,
      isSubmitterView,
    });

    return this.request$<TimesheetViewResponse>('GET', url, null, params);
  }

  /**
   * Gets Weeks by user year week and amount.
   *
   * @param unitId
   * @param year
   * @param week
   * @param amount
   */
  getTimesheetWeeks$(
    unitId: number,
    year: number,
    week: number,
    amount: number,
  ): Observable<HttpEvent<TimeTrackingWeekNavigatorViewResponse>> {
    const url = `/Units/${unitId}/Timesheets/WeekNavigator/${year}/${week}/NumberOfWeeks/${amount}`;

    return this.request$<TimeTrackingWeekNavigatorViewResponse>('GET', url, null);
  }

  /**
   * Generates an Excel file export of the given timesheet.
   */
  getTimesheetExport$(
    unitId: number,
    year: number,
    week: number,
    withCrossUtilize: boolean,
    templateView: TimesheetExportViewType,
  ): Observable<HttpEvent<FileDownloadResponse>> {
    const url = `/Units/${unitId}/Timesheets/${year}/${week}/Report/${templateView}`;

    const params = new CustomHttpParams({
      withCrossUtilize,
    });

    return this.request$<FileDownloadResponse>('GET', url, null, params);
  }

  /**
   * Generates an Excel file export of the given timecard.
   */
  getTimecardExport$(userId: string, year: number, week: number): Observable<HttpEvent<FileDownloadResponse>> {
    const url = `/Users/${userId}/Timecard/${year}/${week}/Report`;

    return this.request$<FileDownloadResponse>('GET', url, null);
  }

  /**
   * Gets a page of a list of filtered roles
   *
   * @param {number} [pageNumber]
   * @param {number} [pageSize]
   * @param {any} [columnFilters]
   * @param {string} [sortColumn]
   * @param {SortDirection} [sortDirection]
   * @return {Observable<HttpEvent<RoleListResponse>>}
   */
  getRoles$(
    pageNumber?: number,
    pageSize?: number,
    columnFilters?: any,
    sortColumn?: string,
    sortDirection?: SortDirection,
  ): Observable<HttpEvent<RoleListResponse>> {
    const url = '/Users/Roles';

    const options = new ListQueryOptions(pageNumber, pageSize, columnFilters, sortColumn, sortDirection);

    const queryParameters = options.toHttpParams();

    return this.request$<RoleListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets the time zone list.
   *
   * @return {Observable<HttpEvent<TimeZoneDto[]>>}
   */
  getTimeZones$(): Observable<HttpEvent<TimeZoneDto[]>> {
    const url = '/Users/Timezones';

    return this.request$<TimeZoneDto[]>('GET', url, null);
  }

  getCalendarLinks$(): Observable<HttpEvent<UserCalendarLinksResponse>> {
    const url = '/Users/CalendarLinks';

    return this.request$<UserCalendarLinksResponse>('GET', url, null);
  }

  /**
   * Updates the user's preferences.
   *
   * @return {Observable<HttpEvent<void>>}
   */
  putUserPreferences$(request: UserPreferencesUpdateRequest): Observable<HttpEvent<void>> {
    const url = '/Users/Preferences';

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Gets the currently used database server name
   *
   * @return {Observable<HttpEvent<string>>}
   */
  getDBCreationDate$(): Observable<HttpEvent<Date>> {
    const url = '/Lookup/DBCreationDate';

    return this.request$<Date>('GET', url, null);
  }

  /**
   * Updates the users' settings.
   *
   * @return {Observable<HttpEvent<void>>}
   */
  putUserSettings$(request: UserSettingRequest): Observable<HttpEvent<void>> {
    const url = '/Users/UserSettings';

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Gets a page of a list of filtered work packages
   */
  getWorkPackages$(options: WorkPackageListOptions): Observable<HttpEvent<WorkPackageViewListResponse>> {
    const url = '/WorkPackages';

    const queryParameters = options.toHttpParams();

    return this.request$<WorkPackageViewListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a page of a list of filtered work packages
   */
  getBasicWorkPackages$(
    options: BasicWorkpackagesRequestOptions,
  ): Observable<HttpEvent<BasicWorkPackageViewListResponse>> {
    const url = '/WorkPackages/Basic';

    const queryParameters = new CustomHttpParams({
      limit: options.limit,
      filterString: options.filterString,
      projectId: options.projectId,
    });

    return this.request$<BasicWorkPackageViewListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a page of a list of filtered work packages
   */
  getBasicWorkPackageDetail$(
    projectId: number,
    workPackageId: number,
    userId: string,
  ): Observable<HttpEvent<BasicWorkPackageDetailResponse>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Basic`;

    const queryParameters = new CustomHttpParams({
      userId,
    });

    return this.request$<BasicWorkPackageDetailResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a list of filtered Questions
   */
  getQuestions$(options: UnitQuestionsListOptions): Observable<HttpEvent<QuestionsViewListResponse>> {
    const url = '/Questions';

    const queryParameters = options.toHttpParams();

    return this.request$<QuestionsViewListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a list of work packages for a given level
   *
   * @param {number} projectId
   * @param {number} levelId
   * @return {Observable<HttpEvent<WorkPackageListResponse>>}
   */
  getProjectLevelWorkpackages$(
    projectId: number,
    levelId: number,
  ): Observable<HttpEvent<WorkPackageListResponse>> {
    const url = `/Projects/${projectId}/Levels/${levelId}/WorkPackages`;

    return this.request$<WorkPackageListResponse>('GET', url, null);
  }

  /**
   * Gets a list of work packages for a given project
   *
   * @param {number} projectId
   * @return {Observable<HttpEvent<WorkPackageListResponse>>}
   */
  getProjectWorkpackages$(
    projectId: number,
    orderByLastUpdated: boolean = false,
    filters: ProjectStructureFiltersSettingsModel | null = null,
  ): Observable<HttpEvent<WorkPackageListResponse>> {
    const url = `${environment.apiEndpoint}/Projects/${projectId}/WorkPackages`;

    // we avoid using CustomHttpParams, it double-encode values and deprecated
    // customHttpParams was originally to avoid angular bug, which is fixed since 2017
    let queryParameters = new HttpParams();

    if (orderByLastUpdated) {
      queryParameters = queryParameters.set('orderByLastUpdated', orderByLastUpdated);
    }
    if (filters?.wpStatus?.length) {
      queryParameters = queryParameters.set('wp-status-filter-list', filters.wpStatus?.join(','));
      if (filters?.wpStatusType) {
        queryParameters = queryParameters.set('wp-status-filter-mode', filters.wpStatusType);
      }
    }
    if (filters?.wpSorting) {
      queryParameters = queryParameters.set('wp-sort-field', filters.wpSorting);
      if (filters?.wpSortDirection) {
        queryParameters = queryParameters.set('wp-sort-direction', filters.wpSortDirection);
      }
    }

    return this.doHttpRequest$<WorkPackageListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets a WorkPackageViewResponse by project id and work package id
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @return {Observable<HttpEvent<WorkPackageViewResponse>>}
   */
  getWorkPackageView$(projectId: number, workPackageId: number): Observable<HttpEvent<WorkPackageViewResponse>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}`;

    return this.request$<WorkPackageViewResponse>('GET', url);
  }

  /**
   * Fetches a list of attachments of a specific type from a work package.
   *
   * @param projectId
   * @param workPackageId
   * @param type
   */
  getWorkPackageAttachments$(
    projectId: number,
    workPackageId: number,
    type: DocumentType,
  ): Observable<HttpEvent<AttachmentListResponse>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Attachments`;

    const queryParameters = new CustomHttpParams({
      type,
    });

    return this.request$<AttachmentListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Gets the view for the resource planning of a work package.
   */
  getWorkPackageResourcePlanningView$(
    projectId: number,
    workPackageId: number,
    year: number,
    week: number,
    numberOfWeeks: number,
  ): Observable<HttpEvent<WorkPackageResourcePlanningViewResponse>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/ResourcePlanning/${year}/${week}/NumberOfWeeks/${numberOfWeeks}`;

    return this.request$<WorkPackageResourcePlanningViewResponse>('GET', url);
  }

  /**
   * Gets the Users which have a related Role like WP-Creator, WP-Unit-Coordinator etc.
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {number} questionId
   * @return {Observable<HttpEvent<QuestionUserNotificationListResponse>>}
   */
  getQuestionUserNotificationRecipients$(
    projectId: number,
    workPackageId: number,
    questionId: number | null,
    questionAction: QuestionAction,
  ): Observable<HttpEvent<QuestionUserNotificationListResponse>> {
    let url = '';
    if (questionId != null && questionId > 0) {
      url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Questions/${questionId}/UsersForNotification/${questionAction}`;
    } else {
      url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Questions/UsersForNotification/${questionAction}`;
    }

    return this.request$<QuestionUserNotificationListResponse>('GET', url);
  }

  /**
   * Gets a page of a list of questions
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {number} [pageNumber]
   * @param {number} [pageSize]
   * @param {WorkPackageQuestionColumnFilters} [columnFilters]
   * @param {number} [questionId]
   * @return {Observable<HttpEvent<QuestionListResponse>>}
   */
  getWorkPackageQuestions$(
    projectId: number,
    workPackageId: number,
    pageNumber?: number,
    pageSize?: number,
    columnFilters?: WorkPackageQuestionColumnFilters,
    questionId?: number,
  ): Observable<HttpEvent<QuestionListResponse>> {
    let url = '';
    if (questionId != null) {
      url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Questions/${questionId}`;
    } else {
      url = `/Projects/${projectId}/WorkPackages/${workPackageId}/Questions`;
    }

    const options = new ListQueryOptions(pageNumber, pageSize, columnFilters);

    const queryParameters = options.toHttpParams();

    return this.request$<QuestionListResponse>('GET', url, null, queryParameters);
  }

  /**
   * Updates an existing Work Package
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateRequest} request
   * @returns {Observable<HttpEvent<void>>}
   */
  putWorkPackage$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}`;

    return this.request$<void>('PUT', url, request);
  }

  updateAdditionalSuppliers$(
    projectId: number,
    workPackageId: number,
    request: AdditionalSuppliersUpdateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateAdditionalSuppliers`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates an existing Work Package
   *
   * @param {number} originalProjectId
   * @param {number} originalWorkPackageId
   * @param {WorkPackageUpdateRequest} request
   * @returns {Observable<HttpEvent<WorkPackageCreateResponse>>}
   */
  duplicateWorkPackage$(
    originalProjectId: number,
    originalWorkPackageId: number,
    request: WorkPackageDuplicateRequest,
  ): Observable<HttpEvent<WorkPackageCreateResponse>> {
    const url = `/Projects/${originalProjectId}/WorkPackages/${originalWorkPackageId}/Duplicate`;

    return this.request$<WorkPackageCreateResponse>('POST', url, request);
  }

  moveWorkPackage$(
    originalProjectId: number,
    originalWorkPackageId: number,
    request: WorkPackageMoveRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${originalProjectId}/WorkPackages/${originalWorkPackageId}/Move`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Deletes a specific Work Package
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @returns {Observable<HttpEvent<void>>}
   */
  deleteWorkPackage$(projectId: number, workPackageId: number): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}`;

    return this.request$<void>('DELETE', url, null);
  }

  /**
   * Creates a new Work Package
   *
   * @param {number} projectId
   * @param {WorkPackageCreateRequest} request
   * @returns {Observable<HttpEvent<WorkPackageCreateResponse>>}
   */
  postWorkPackage$(
    projectId: number,
    request: WorkPackageCreateRequest,
  ): Observable<HttpEvent<WorkPackageCreateResponse>> {
    const url = `/Projects/${projectId}/WorkPackages`;

    return this.request$<WorkPackageCreateResponse>('POST', url, request);
  }

  /**
   * Post data to create ad-hoc workpackage
   * @param request
   * @returns
   */
  postAdhocWorkPackage$(
    requestPayload: AdhocWorkpackageRequestModel,
  ): Observable<HttpEvent<WorkPackageCreateResponse>> {
    const url = '/CreateAdHoc';
    return this.request$<WorkPackageCreateResponse>('POST', url, requestPayload);
  }

  /**
   * Confirms the resource planning of a work package.
   *
   * @param projectId
   * @param workPackageId
   * @param request
   */
  confirmWorkPackageResourcePlanning$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageResourcePlanningUpdateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/ResourcePlanning/Confirm`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Saves the resource planning of a work package.
   *
   * @param projectId
   * @param workPackageId
   * @param request
   */
  saveWorkPackageResourcePlanning$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageResourcePlanningUpdateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/ResourcePlanning/Save`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Cancels the resource planning of a work package.
   *
   * @param projectId
   * @param workPackageId
   * @param request
   */
  cancelWorkPackageResourcePlanning$(
    projectId: number,
    workPackageId: number,
    request: OldPlannedResourcesRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/ResourcePlanning/Cancel`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates a specific Work Package status to Forecasted
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateStatusRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToForecasted$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateStatusRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateToForecasted`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates a specific work package status to Draft
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateStatusRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToDraft$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateStatusRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/ReturnToDraft`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates a specific work package status to Issued
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateStatusRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToIssued$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateStatusRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateToIssued`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates a specific work package status to InProgress
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageCloseRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToClosed$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageCloseRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateToClosed`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates a specific work package status to InProgress
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateStatusRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToInProgress$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateStatusRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateToInProgress`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Self-assign a specific work package
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateStatusRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  selfAssignWorkPackage$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateStatusRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/SelfAssign`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates a specific work package status to Confirmed
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateStatusRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToConfirmed$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateStatusRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateToConfirmed`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates a specific work package status to On Hold
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateStatusRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToOnHold$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateStatusRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateToOnHold`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates a specific work package status to Quality Assurance
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateStatusRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToQualityAssurance$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateStatusRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateToQualityAssurance`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Upload output Documents for Workpackage Detail
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUploadOutputDocumentsRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  postOutputDocuments$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUploadOutputDocumentsRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/OutputDocuments`;

    return this.request$<void>('POST', url, request);
  }

  /**
   * Create a new rework entry and do the work package transition
   *
   * @param {number} projectId
   * @param {WorkPackageReworkCreateRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  postWorkPackageRework$(
    projectId: number,
    request: WorkPackageReworkCreateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${request.workPackageId}/Rework`;

    return this.request$<void>('POST', url, request);
  }

  /**
   * Create a new feedback entry
   *
   * @param {number} projectId
   * @param {FeedbackCreateRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  postFeedback$(projectId: number, request: FeedbackCreateRequest): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${request.workPackageId}/Feedback`;

    return this.request$<void>('POST', url, request);
  }

  /**
   * Updates a specific work package status to Scope Change and creates the related scope change
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {ScopeChangeCreateRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  postWorkPackageScopeChange$(
    projectId: number,
    workPackageId: number,
    request: ScopeChangeCreateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/ScopeChange`;

    return this.request$<void>('POST', url, request);
  }

  /**
   * Updates an existing scope change
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {number} scopeChangeId
   * @param {ScopeChangeUpdateRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageScopeChange$(
    projectId: number,
    workPackageId: number,
    scopeChangeId: number,
    request: ScopeChangeUpdateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/ScopeChange/${scopeChangeId}`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Deletes an existing scope change
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {number} scopeChangeId
   * @return {Observable<HttpEvent<void>>}
   */
  deleteWorkPackageScopeChange$(
    projectId: number,
    workPackageId: number,
    scopeChangeId: number,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/ScopeChange/${scopeChangeId}`;

    return this.request$<void>('DELETE', url, null);
  }

  /**
   * Updates a specific work package status to Ready For Scope Change Approval"
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateStatusRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToReadyForScopeChangeApproval$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateStatusRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateToReadyForScopeChangeApproval`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates a specific work package status to Ready to Resume Work
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {SuspensionCreateRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToReadyToResumeWork$(
    projectId: number,
    workPackageId: number,
    request: SuspensionCreateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateToReadyToResumeWork`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates a specific work package status to ScopeChange
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateStatusRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToScopeChange$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateStatusRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateToScopeChange`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates a specific work package status to Ready for Approval"
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateStatusRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageStatusToReadyForApproval$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateStatusRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateToReadyForApproval`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates submitter settings
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageSubmitterSettingsUpdateRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageSubmitterSettings$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageSubmitterSettingsUpdateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateSubmitterSettings`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Updates supplier settings
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageSupplierSettingsUpdateRequest} request
   * @return {Observable<HttpEvent<void>>}
   */
  putWorkPackageSupplierSettings$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageSupplierSettingsUpdateRequest,
  ): Observable<HttpEvent<void>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/UpdateSupplierSettings`;

    return this.request$<void>('PUT', url, request);
  }

  /**
   * Fetches the dashboard view.
   *
   * @return {Observable<HttpEvent<DashboardViewResponse>>}
   */
  getDashboard$(): Observable<HttpEvent<DashboardViewResponse>> {
    const url = '/Dashboard';

    return this.request$<DashboardViewResponse>('GET', url, null);
  }

  /**
   * Fetches the latest projects of a given user for display on the dashboard view.
   *
   * @param userId
   * @return {Observable<HttpEvent<ProjectDashboardDto[]>>}
   */
  getDashboardProjects$(userId: string): Observable<HttpEvent<ProjectDashboardDto[]>> {
    const url = `/Dashboard/Projects/${userId}`;

    return this.request$<ProjectDashboardDto[]>('GET', url, null);
  }

  /**
   * Fetches the latest work packages of a given user for display on the dashboard view,
   * filtered by their status.
   *
   * @param userId
   * @param status
   * @param forSupplierUnitCoordinator
   * @return {Observable<HttpEvent<ProjectDashboardDto[]>>}
   */
  getDashboardWorkPackages$(
    userId: string,
    status: WorkPackageStatus[],
    viewTypes: DashboardWorkPackageViewType[],
    forSupplierUnitCoordinator?: boolean,
  ): Observable<HttpEvent<WorkPackageDashboardDto[]>> {
    const url = '/Dashboard/WorkPackages/';

    const queryParameters = new CustomHttpParams({
      userId,
      statusFilters: status,
      forSupplierUnitCoordinator,
      workPackageViewTypes: viewTypes,
    });

    return this.request$<WorkPackageDashboardDto[]>('GET', url, null, queryParameters);
  }

  /**
   * Fetches the latest questions of a given user for display on the dashboard view.
   *
   * @param userId
   * @return {Observable<HttpEvent<QuestionDashboardDto[]>>}
   */
  getDashboardQuestions$(userId: string): Observable<HttpEvent<QuestionDashboardDto[]>> {
    const url = `/Dashboard/Questions/${userId}`;

    return this.request$<QuestionDashboardDto[]>('GET', url, null);
  }

  /**
   * Fetches the view type filters for the dashboard.
   *
   * @param userId
   * @return {Observable<HttpEvent<DashboardFiltersVisibilityResponse>>}
   */
  getDashboardFiltersVisibility$(): Observable<HttpEvent<DashboardFiltersVisibilityResponse>> {
    const url = '/Dashboard/Filters';

    return this.request$<DashboardFiltersVisibilityResponse>('GET', url, null);
  }

  getUserNotificationView$(): Observable<HttpEvent<UserNotificationViewResponse>> {
    const url = '/Users/NotificationSettings';

    return this.request$<UserNotificationViewResponse>('GET', url, null);
  }

  putUserNotificationView$(request: UserNotificationSettingsUpdateRequest): Observable<HttpEvent<void>> {
    const url = '/Users/NotificationSettings';

    return this.request$<void>('PUT', url, request);
  }

  getUserNotificationDefaults$(roleCode: string): Observable<HttpEvent<UserNotificationSettingsDto[]>> {
    const url = '/Users/NotificationSettings/Default';

    const queryParameters = new CustomHttpParams({
      roleCode,
    });

    return this.request$<UserNotificationSettingsDto[]>('GET', url, null, queryParameters);
  }

  getWorkPackageFeedbackRecipients$(
    projectId: number,
    workPackageId: number,
    isSupplierFeedback: boolean,
  ): Observable<HttpEvent<UserSlimDto[]>> {
    const url = `/Projects/${projectId}/WorkPackages/${workPackageId}/FeedbackRecipients`;

    const queryParameters = new CustomHttpParams({
      isSupplierFeedback,
    });

    return this.request$<UserSlimDto[]>('GET', url, null, queryParameters);
  }

  getSkills$(unitId: number): Observable<HttpEvent<SkillsResponse>> {
    const url = `/Skills/${unitId}`;

    return this.request$<SkillsResponse>('GET', url, null);
  }

  putSkills$(unitId: number, request: UpdateSkillsRequest): Observable<HttpEvent<void>> {
    const url = `/Skills/${unitId}`;

    return this.request$<void>('PUT', url, request);
  }

  getSkillCategories$(unitId: number): Observable<HttpEvent<SkillCategoryDto[]>> {
    const url = `/Skills/Categories/${unitId}`;

    return this.request$<SkillCategoryDto[]>('GET', url, null);
  }

  putSkillCategories$(unitId: number, request: UpdateSkillCategoryRequest): Observable<HttpEvent<void>> {
    const url = `/Skills/Categories/${unitId}`;

    return this.request$<void>('PUT', url, request);
  }

  getSkillUnits$(): Observable<HttpEvent<UnitSkillDto[]>> {
    const url = '/Units/Skills';

    return this.request$<UnitSkillDto[]>('GET', url, null);
  }

  getSkillRatings$(unitId: number): Observable<HttpEvent<SkillRatingResponse>> {
    const url = `/Skills/Ratings/${unitId}`;

    return this.request$<SkillRatingResponse>('GET', url, null);
  }

  putSkillRatings$(unitId: number, request: UpdateUserSkillRatingsRequest): Observable<HttpEvent<void>> {
    const url = `/Skills/Ratings/${unitId}`;

    return this.request$<void>('PUT', url, request);
  }

  getHourlyRatesCategories$(userId: string): Observable<HttpEvent<string[]>> {
    const url = `/Units/HourlyRateCategoriesOfUserUnit/${userId}`;

    return this.request$<string[]>('GET', url, null);
  }

  /**
   * Delete a specific holiday
   */
  deleteHoliday$(holidaysToDelete: PublicHolidayDeleteDto): Observable<HttpEvent<void>> {
    const url = `/PublicHolidays`;
    return this.request$<void>('DELETE', url, holidaysToDelete);
  }

  /**
  * Update a specific holiday
  */
  updateHoliday$(holidaysToUpdate: PublicHolidaysUpdateDto): Observable<HttpEvent<void>> {
    const url = `/PublicHolidays/Update`;
    return this.request$<void>('PUT', url, holidaysToUpdate);
  }

  /**
  * Copy all of the holidays of the specified cities to the next year
  */
  copyHolidaysToNextYear$(holidaysToCreateCopyOf: PublicHolidaysCreateCopy): Observable<HttpEvent<void>> {
    const url = `/PublicHolidays/Create`;
    return this.request$<void>('POST', url, holidaysToCreateCopyOf);
  }

  /**
  * Get all public holidays cities
  */
  getPublicHolidayCities$(): Observable<HttpEvent<PublicHolidaysCitiesDto[]>> {
    const url = `/Cities`;
    return this.request$<PublicHolidaysCitiesDto[]>('GET', url);
  }

  /**
  * Get all public holidays for the specified year and cities
  */
  getPublicHolidayData$(year: number, cities: number[]): Observable<HttpEvent<PublicHolidayDto[]>> {
    const url = `/PublicHolidays?cityIds=${  encodeURIComponent(cities.join(';'))  }&year=${  year}`;
    return this.request$<PublicHolidayDto[]>('GET', url);
  }

  // eslint-disable-next-line max-len
  private makeHeaderFilterRequestParams(headerFilters: CollappFilterDropdownListRadioSetting[], queryParameters: HttpParams): HttpParams {
    let queryParams = queryParameters;
    if (headerFilters && headerFilters.length) {
      headerFilters.forEach((filterItem: CollappFilterDropdownListRadioSetting) => {
        if (filterItem.selectedListIds && filterItem.selectedListIds.length) {
          queryParams = queryParams.set(`${filterItem.filterId}-ids`, filterItem.selectedListIds.join(','));
        }
        if (filterItem.selectedRadioId) {
          queryParams = queryParams.set(`${filterItem.filterId}-mode`, filterItem.selectedRadioId);
        }
      });
    }
    return queryParams;
  }

  // eslint-disable-next-line max-lines-per-function
  private request$<T>(
    method: string,
    url: string,
    body?: any,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<HttpEvent<T>> {
    const params = (queryParameters ? queryParameters.toHttpParams() : {});

    const request$ = this.httpClient.request<T>(
      method,
      getEndpointUrl(url),
      {
        body,
        headers,
        reportProgress: true,
        observe: 'events',
        params,
      },
    );

    return request$
      .pipe(
        catchError((e: unknown) => {
          const error = e as HttpErrorResponse;
          const customErrorData = this.prepareError(error);
          // eslint-disable-next-line max-len
          const customError = new CustomHttpErrorResponse(customErrorData.customStatusText, customErrorData.errorMessage, error as any);

          return throwError(customError);
        }),
      );
  }

  /**
   * Do a http request without using CustomHttpParams
   * @param method
   * @param url
   * @param body
   * @param queryParameters
   * @param headers
   * @returns
   */
  // eslint-disable-next-line max-lines-per-function
  private doHttpRequest$<T>(
    method: string,
    url: string,
    body?: any,
    queryParameters?: HttpParams,
    headers?: HttpHeaders,
  ): Observable<HttpEvent<T>> {
    const params = queryParameters || {};

    const request$ = this.httpClient.request<T>(
      method,
      url,
      {
        body,
        headers,
        reportProgress: true,
        observe: 'events',
        params,
      },
    );

    return request$
      .pipe(
        catchError((e: unknown) => {
          const error = e as HttpErrorResponse;
          const customErrorData = this.prepareError(error);
          // eslint-disable-next-line max-len
          const customError = new CustomHttpErrorResponse(customErrorData.customStatusText, customErrorData.errorMessage, error as any);

          return throwError(customError);
        }),
      );
  }

  /**
   * Prepare the data for a customError
   * @param error
   * @returns
   */
  private prepareError(error: HttpErrorResponse): { customStatusText: string; errorMessage: string } {
    let statusCode: number = error.status;
    const { statusText } = error;
    const allErrors: string[] = [];

    if (error.error) {
      const errorResponse = error.error;
      if (typeof errorResponse === 'string') {
        allErrors.push(errorResponse);
      } else if (typeof errorResponse === 'object') {
        if (
          'statusCode' in errorResponse
          && 'message' in errorResponse
        ) {
          // eslint-disable-next-line max-depth
          if (errorResponse.statusCode) {
            statusCode = errorResponse.statusCode;
          }

          allErrors.push(errorResponse.message);
        } else {
          Object.values(errorResponse).forEach((errors) => {
            if (Array.isArray(errors)) {
              allErrors.push(...errors);
            }
          });
        }
      }
    }

    const customStatusText = `${statusCode > 0 ? `${statusCode} ` : ''}${statusText}`;
    const errorMessage = allErrors.join(' \n');

    return { customStatusText, errorMessage };
  }
}
